@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap');
body{
    background-color: #eeeeee;
    font-family: 'Comic Neue', sans-serif;
}

.MenuBtn{
    margin-top: 2px;
    margin-bottom: 2px;
    color: '#0494c4';
    font-weight:'600';
    display: 'block';
}
.MenuBtn:hover{
    background-color: #ddd;
}

#game-selector-container{
    display:flex;
    justify-content:center; 
    padding:15px; 
    overflow: auto; 
    margin-top: 104px; 
    height:80vh;
    gap: 30px; 
    flex-wrap: wrap;
    border: 1px solid #bdbdbd;
    background-color: rgba(0, 0, 0, 0.5);    
}

.CategoryCards{
    max-width: 345px; 
    height:300px;
    /* background: linear-gradient(45deg, #989898 30%, #d6d5d4 90%); */
    box-shadow: 0 4px 8px 0 rgba(177, 176, 176, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    transition: transform 0.5s ease-in-out;
    border: 1px solid #ddd;
  
}

.CategoryCards:hover {
    transform: scale(1.05); 
  }

  .gameselectorcardstyle{
    color:#fff;
    width:100%; 
    height:60%; 
    align-content:center;
    align-items:center;
  } 
  .purple-gradient {
    background: linear-gradient(135deg, #8e44ad, #c0392b);
    
  }
  .blue-gradient {
    background: linear-gradient(135deg, #3498db, #2c3e50);
  }

  .green-gradient {
    background: linear-gradient(135deg, #2ecc71, #27ae60);
  }

  .yellow-gradient {
    background: linear-gradient(135deg, #f1c40f, #f39c12);
  }

  .pink-gradient {
    background: linear-gradient(135deg, #e74c3c, #ff00ff);
  }

  .orange-gradient {
    background: linear-gradient(135deg, #e67e22, #d35400);
  }