/* *{
  box-sizing: border-box;
  } */
  body{
    background-image: url('/public/nature.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    /*background-image: linear-gradient(to right bottom, #051937, #004d7a, #008793, #00bf72, #a8eb12);*/
       
       /* min-height: 100vh;*/
     /*   display: flex;*/
        align-items: center; 
        justify-content: center;
        font-family:Arial, Helvetica, sans-serif;         
  }

  .strikethrough {
    text-decoration: line-through;
    color: red;
    font-weight: bolder;
    /* Additional styling if needed */
  }
  @keyframes glow {
    from {
      text-shadow: 0 0 5px #fff, 0 0 20px #fff, 0 0 30px #1b5e20, 0 0 40px #1b5e20, 0 0 50px #1b5e20, 0 0 60px #1b5e20, 0 0 70px #1b5e20;
    }
    
    to {
      text-shadow: 0 0 10px #fff, 0 0 30px #388e3c, 0 0 40px #388e3c, 0 0 50px #388e3c, 0 0 60px #388e3c, 0 0 70px #388e3c, 0 0 80px #388e3c;
    }
  }
  
  .correct-answer-highlighted { 
    color: #1b5e20;
    font-weight: bolder;
    text-align: center;   
    animation:glow 1s ease-in-out infinite alternate;
    pointer-events: none;
  }

  .fade-in {
    opacity: 0;
    transition: opacity 0.5s ease-in-out; /* Add transition for opacity */
  }
  
  .fade-in.visible {
    opacity: 1;
  }


  @keyframes glowbtn {
    0% {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    }
    100% {
      box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.5);
    }
  }
  
  /* Style for the button */
  .glow-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    animation: glowbtn 1.5s infinite alternate; /* Apply the glow animation */
  }

  @keyframes glowdarkbtn {
    0% {
      box-shadow: 0 0 10px 0 rgba(224, 224, 12, 0.89);
    }
    100% {
      box-shadow: 0 0 20px 10px rgba(234, 231, 231, 0.972);
    }
  }
  
  /* Style for the button */
  .glow-dark-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    color: #fff;
    background-color: #cd8a05;
    cursor: pointer;
    animation: glowdarkbtn 1.5s infinite alternate; /* Apply the glow animation */
  }


  .card{
    position: relative; 
    background-color: #fff;
    width: 800px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    padding: 0;
    margin-top: 25px;
  }

  .card-header{
    padding: 2rem 0 0.5rem 2rem;
    
  }
  .card-text{
    font-size: larger;
    font-weight: bold;
    border-bottom: 1px solid #263238;
    color:#37474f;
  }
  .card-header>p{
    word-wrap: break-word;
    margin-right: 15px;
    padding: 10px;
  }
 .card-message{
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight: 900;
   font-size: large;
 }

  .card-body{
    padding: 2rem 0 0.5rem 2rem;
  
  }

  .card-footer{
     padding: 15px;
     display: flex; 
     justify-content: flex-end;
  }


  .custom-radio {
  margin-bottom: 10px;
}

.custom-radio input[type="radio"] {
  display: none; /* Hide the default radio button */
  line-height: 1.5; /* Adjust line height */
}

.custom-radio label {
  cursor: pointer;
  padding: 10px;
  margin-right: 10px;
  padding-left: 25px;
  position: relative; /* Position relative for pseudo-element */
}


/* Circle bullet point */
.bulletpointyellow {
  content: "";
  display: block;
  width: 18px; /* Diameter of the circle */
  height: 18px; /* Diameter of the circle */
  border-radius: 50%; /* Shape the div into a circle */
  border: 2px solid #ffa600; /* Border color */
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%); /* Center the circle vertically */

}

.bulletpointblue {
  content: "";
  display: block;
  width: 18px; /* Diameter of the circle */
  height: 18px; /* Diameter of the circle */
  border-radius: 50%; /* Shape the div into a circle */
  border: 2px solid #007bff; /* Border color */
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%); /* Center the circle vertically */

}
.bulletpointgreen {
  content: "";
  display: block;
  width: 18px; /* Diameter of the circle */
  height: 18px; /* Diameter of the circle */
  border-radius: 50%; /* Shape the div into a circle */
  border: 2px solid #3f8835; /* Border color */
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%); /* Center the circle vertically */

}
.bulletpointred {
  content: "";
  display: block;
  width: 18px; /* Diameter of the circle */
  height: 18px; /* Diameter of the circle */
  border-radius: 50%; /* Shape the div into a circle */
  border: 2px solid #be3131; /* Border color */
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%); /* Center the circle vertically */

}
/* Horizontal bottom border animation */
.custom-radio label::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  transition: width 0.5s ease; /* Transition animation */
}

/* Animate border to the right when radio button is checked */
.custom-radio input[type="radio"]:checked + label::after {
  width: 100%;
  color: #fff; /* Text color for selected */
  transform: scale(1.2); /* Increase size when selected */
}
.blue input[type="radio"]:checked + label::after {
  background-color: #007bff; /* Background color for selected */
  box-shadow: 0 0 10px #007bff; /* Add glow effect */
}

.red input[type="radio"]:checked + label::after {
  background-color: #b90434; /* Initial border color */
  box-shadow: 0 0 10px #b90434; /* Add glow effect */
}
.green input[type="radio"]:checked + label::after {
  background-color: #038e08; /* Initial border color */ 
  box-shadow: 0 0 10px #038e08; /* Add glow effect */
}
.yellow input[type="radio"]:checked + label::after {
  background-color: #cd9c09; /* Initial border color */ 
  box-shadow: 0 0 10px #dead1a; /* Add glow effect */
}

.custom-radio input[type="radio"]:checked + label .blueselected {
  /* Styles for the .styled-div when the radio button is checked */
  /* Example styles: */
  background-color: lightblue;
  color: white;
  box-shadow: 0 0 20px #007bff; 
}

.custom-radio input[type="radio"]:checked + label .redselected {
  /* Styles for the .styled-div when the radio button is checked */
  /* Example styles: */
  background-color: rgb(230, 173, 187);
  color: white;
  box-shadow: 0 0 20px #ff0048; 
}

.custom-radio input[type="radio"]:checked + label .greenselected {
  /* Styles for the .styled-div when the radio button is checked */
  /* Example styles: */
  background-color: #90e793;
  color: white;
  box-shadow: 0 0 20px #00ffb7; 
}

.custom-radio input[type="radio"]:checked + label .yellowselected {
  /* Styles for the .styled-div when the radio button is checked */
  /* Example styles: */
  background-color: rgb(238, 229, 145);
  color: white;
  box-shadow: 0 0 20px #ff7300; 
}
.btn{
  float: right;
  margin: 15px;
}

#failed-question-div{
  max-height: 600px;
  overflow-y: scroll;
  border: 1px solid #C7C6C6;
}


.tblstyle {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.tblstyle td, .tblstyle th {
  border: 1px solid #ddd;
  padding: 8px;
}

.tblstyle tr:nth-child(even){background-color: #414040;}

.tblstyle tr:hover {background-color: #ddd;}

.tblstyle th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
/*Bootstrap override*/
/* Default styles for form-check */
.form-check {
  display: block;
  position: relative;
  padding-left: 1.25rem; /* Adjust this value to change the distance between the checkbox and label */
  padding: 10px;
}
.game-space-parent-div{
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);  
  padding: 0px; 
  min-height: 80%;
  margin-top: 100px;
}

.game-space-parent-div-label{
  width: inherit;
  background-color: #000;
  opacity: 0.8;
  margin: 0px;
  padding: 10px;
  color: #fff;
}

.game-space{
  background-color: rgba(255, 255, 255, 0.5);  
  padding-top: 0px;
  padding-bottom: 60px;
  padding-left: 40px;
  padding-right: 40px;
  min-height: 90%;
  margin-top: 0;
}

.game-over-space{
  width: 70%;
  background-color: #000;  
  padding-top: 10px;
  padding-bottom: 60px;
  padding-left: 40px;
  padding-right: 40px;
  min-height: 90%;
  margin-top: 120px;
  color:#fff;
  font-weight: bolder;
}

.quenstion-number{
  margin: 5px;
  float: right;
  font-weight: bold;
  font-size: larger;
  color:#6200ea;
  background-color: #fff;
  padding: 10px; 
  border-radius: 5%; /* Shape the div into a circle */
}

.score-div{
  width: 40px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  background-color: #3498db; /* Example background color */
  border-radius: 50%; /* Makes it round */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2e7d32;
  color:#fff;
  margin: auto;
  float: right;
  animation: grow-shrink 2s infinite alternate; /* Animation properties */
  border: 2px solid #7b1fa2;
}
@keyframes grow-shrink {
  0% {
    transform: scale(1); /* Initial scale */
  }
  100% {
    transform: scale(1.25); /* Final scale */
  }
}

.timer-div{
  width: 80px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  border-radius: 5%; /* Makes it round */
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  float: right;
}

.life-bar-div{
  width: 80px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */  
  justify-content: center;
  align-items: center; 
}

.wrong-div{
  width: 40px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  background-color: #3498db; /* Example background color */
  border-radius: 50%; /* Makes it round */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #be3131;
  color:#fff;
}

/* Responsive design using media queries */
@media only screen and (max-width: 600px) {
  /* Adjust styles for smaller screens */
  .card{   
   width: 100%;
    
 }
 .tblstyle{
  width: 100%;
 }
}

/* Styles for tablets in portrait mode (width up to 768 pixels) */
@media only screen and (max-width: 768px) {
  /* Your tablet portrait styles here */
  .card{   
    width: 100%;  
  }
  .tblstyle{
    width: 100%;
    margin-left: 2px;
    margin-right: 2px;
   }
}

/* Styles for tablets in landscape mode (width between 769 and 1024 pixels) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  /* Your tablet landscape styles here */
  .card{   
    width: 100%;  
  }
  .tblstyle{
    width: 100%;
   }
}