.life-bar {
    width: 100%;
    height: 30px;
    border: 2px solid #000;
    border-radius: 25px;
    overflow: hidden;
    background-color: transparent;
    text-align: center;
    align-content: center;
  }
  
  .life-fill {
    height: 100%;
    background: rgb(241,9,10);
    background: linear-gradient(90deg, rgba(241,9,10,1) 0%, rgba(226,231,15,1) 40%, rgba(54,107,47,1) 100%);
    transition: width 0.3s ease;
    text-align: center;
    align-content: center;
  }




  @keyframes slow-life-glow {
    0% {
      box-shadow: 0 0 15px 0 rgba(11, 131, 67, 0.5);
    }
    100% {
      box-shadow: 0 0 25px 15px rgba(1, 109, 41, 0.5);
    }
  }
  @keyframes medium-life-glow {
    0% {
      box-shadow: 0 0 15px 0 rgba(247, 126, 51, 0.5);
    }
    100% {
      box-shadow: 0 0 25px 15px rgba(249, 109, 1, 0.5);
    }
  }
  @keyframes fast-life-glow {
    0% {
      box-shadow: 0 0 15px 0 rgba(251, 96, 96, 0.5);
    }
    100% {
      box-shadow: 0 0 25px 15px rgba(237, 1, 1, 0.5);
    }
  }
  /* Slow Glow */
  .slow-glow {
    animation: slow-life-glow 2s infinite alternate;
  }
  
  /* Medium Glow */
  .medium-glow {
    animation: medium-life-glow 1.5s infinite alternate;
  }
  
  /* Fast Glow */  
  .fast-glow {
    animation: fast-life-glow 1s infinite alternate;
  }