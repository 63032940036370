.faq-container {
    width: 50%;
    margin: auto;
    margin-top: 5%;
    background-color: #b39ddb;
    padding: 15px;
  }
  
  .faq-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .faq-question {
    background-color: #f0f0f0;
    padding: 10px;
    font-weight: bold;
    font-size: larger;
    
  }
  
  .faq-answer {
    padding: 10px;
    background-color: #f0f0f0;
    color: #0d47a1;
    font-weight: 600;
    border-top: 1px solid #bdbdbd;
    font-size: large;
  }

  .chevron-circle {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #b388ff;
    text-align: center;
    line-height: 24px; /* Center the icon vertically */
    margin-left: 15px; /* Push the chevron to the right */
    margin-right: 0;
  }
  
  .chevron-circle.open {
    background-color: #007bff; /* Change color when open */
  }
  
  .chevron-circle svg {
    color: white; /* Set chevron color */
  }