.resource-card:hover {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }

.resource-card:hover{
    transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.resource-card-img-div{
     max-height: 600px;

     font-size: larger;
}

.resource-card-img-div >img{
    height: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}